<template>
  <div
    class="header"
    :class="{ headerlogin: isLogin }"
    id="wcag"
    tabindex="-1"
  >
    <div class="headerLeft">
      <Logo :logo-src="isLogin ? loginLogoSrc : logoSrc" />
      <div
        class="info"
        v-html="desc"
      />
    </div>
    <div
      v-if="!isLogin && !hideRightItems"
      class="wapShow wapList"
    >
      <b-icon
        icon="list"
        font-scale="2.5"
        @click="wapSidebar"
        :class="{ isListActive: isListActive }"
      />
    </div>
    <div
      v-if="!isLogin"
      class="d-flex align-items-center"
      :class="{ wapHide: !hideRightItems }"
    >
      <SelectLanguage
        :is-has-select="true"
        class="headerLanguage mr-3"
      />
      <template v-if="!hideRightItems">
        <User
          class="mr-3"
          v-if="errorLogin"
        />
        <News
          class="mr-3"
          v-if="errorLogin"
          v-permission="PW_Worker_Portal_Code.CompaignCode.Message"
        />
        <div
          class="logoutBtn"
          v-permission="$p.PW_Worker_Portal_Code.LogOut"
        >
          <LogoutBtn />
        </div>
      </template>
    </div>
    <SelectLanguage
      v-else
      class="headerLanguage"
    />
  </div>
</template>
<script>
import { Logo, LogoutBtn, globalEnv } from "fe-infrastractures";
import User from "./components/user.vue";
import News from "./components/news.vue";
import SelectLanguage from "./components/select-language.vue";
import { getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import { PW_Worker_Portal_Code } from "@/router/permissionCode";

export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    errorLogin: {
      type: Boolean,
      default: true,
    },
    hideRightItems: {
      type: Boolean,
      default: false,
    },
    listActiveNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    let sysPath = "";
    if (globalEnv.VUE_APP_SYSTEM_PATH) {
      sysPath = "/" + globalEnv.VUE_APP_SYSTEM_PATH;
    }
    let logoSrc = globalEnv.VUE_APP_STD_URL + sysPath + "/anon/system/logo";
    return {
      PW_Worker_Portal_Code,
      desc: "",
      logoSrc: logoSrc,
      loginLogoSrc:
        globalEnv.VUE_APP_STD_URL + sysPath + "/anon/system/login-logo",
      isListActive: false,
    };
  },
  components: { Logo, SelectLanguage, User, LogoutBtn, News },
  mounted() {
    getPortalPageSetting(pageNameEnum.Top).then((data) => {
      this.desc = data.data.description;
    });
    this.wapLayout();
    this.$nextTick(() => {
      document.getElementById("wcag").focus();
    });
  },
  methods: {
    wapSidebar() {
      let siderbar = document.getElementsByClassName("sidebarTag")[0];
      if (siderbar) {
        if (siderbar.classList.contains("open")) {
          document.body.style.overflow = "";
          siderbar.classList.remove("open");
          this.isListActive = false;
        } else {
          document.body.style.overflow = "hidden";
          siderbar.classList.add("open");
          this.isListActive = true;
        }
      }
    },
    wapLayout() {
      let siderbar = document.getElementsByClassName("sidebarTag")[0];
      if (siderbar) {
        if (!siderbar.classList.contains("open")) {
          document.body.style.overflow = "";
        }
      }
      window.addEventListener("resize", function () {
        if (window.innerWidth > 1024) {
          document.body.style.overflow = "";
        }
      });
    },
  },
  watch: {
    listActiveNum() {
      this.isListActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.logoutBtn {
  > span {
    background-color: var(--topMenuFont);
    border: 2px solid var(--topMenuFont);
    color: var(--topMenuBackground);
    display: inline-block;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;

    .b-icon.bi {
      display: block;
      font-size: 150%;

      &:hover {
        color: inherit;
      }
    }

    &:hover {
      background-color: var(--linkFontActive);
      border: 2px solid var(--linkFontActive);
      color: var(--topMenuFont);

      .b-icon.bi {
        color: inherit;
      }
    }
  }
}

.wapList {
  .b-icon.bi {
    color: var(--topMenuFont);

    &.isListActive {
      color: var(--linkFontActive) !important;
    }
  }
}
</style>
