<template>
  <div style="margin-right: 0.6rem" class="newsIcon">
    <span
      id="news_1"
      v-b-tooltip="{
        trigger: 'hover',
        title: $g('messages'),
        boundary: 'window',
        container: 'news_1'
      }"
      @click="toNotification"
    >
      <b-icon icon="bell" font-scale="1.5" />
      <span class="newsIcon_msg">{{ getMessageCount }}</span>
    </span>
  </div>
</template>

<script>
import { storeage } from "fe-infrastractures";
import { messageCount } from "@/api/messages";

export default {
  data() {
    return {
      messageCount: 0
    };
  },
  created() {
    this.initMessageCount();
  },
  methods: {
    toNotification() {
      let query = {
        t: new Date().getTime()
      };
      this.$router.push({ name: "messages", query: query });
    },
    initMessageCount() {
      messageCount().then((result) => {
        this.messageCount = result.data;
        this.$store.dispatch("app/setPortalMessageCount", this.messageCount);
      });
    }
  },
  computed: {
    userName() {
      if (
        storeage.getUserInfo()?.lastName &&
        storeage.getUserInfo()?.firstName
      ) {
        return (
          storeage.getUserInfo()?.lastName +
          ", " +
          storeage.getUserInfo()?.firstName
        );
      } else if (storeage.getUserInfo()?.lastName) {
        return storeage.getUserInfo()?.lastName;
      } else if (storeage.getUserInfo()?.firstName) {
        return storeage.getUserInfo()?.firstName;
      } else {
        return "";
      }
    },
    getMessageCount() {
      return this.$store.state.app.portalMessageCount || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.newsIcon {
  position: relative;
}
.newsIcon_msg {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: var(--featuredFont);
  border-radius: 6px;
  color: #fff;
  padding: 0px 2px;
  font-size: 12px;
  cursor: pointer;
  min-width: 18px;
  min-height: 18px;
  text-align: center;
}
#news_1 {
  background-color: var(--topMenuFont);
  border: 2px solid var(--topMenuFont);
  color: var(--topMenuBackground);
  display: inline-block;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  .b-icon.bi {
    display: block;
    &:hover {
      color: inherit;
    }
  }
  &:hover {
    background-color: var(--linkFontActive);
    border: 2px solid var(--linkFontActive);
    color: var(--topMenuFont);
    .b-icon.bi {
      color: inherit;
    }
  }
}
</style>
