<template>
  <div class="footer">
    <div v-html="desc"></div>
    <div>
      <div class="contactUs">
        <k-link class="eventFont w-150" @click="contactUs" v-show="isContactUs && isShowContactUs"
          >{{ $g("contactUs") }}
          <b-icon icon="arrow-right"></b-icon>
        </k-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getIsHideConactUs, getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
export default {
  props: {
    isContactUs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      desc: "",
      isShowContactUs: false,
    };
  },
  mounted() {
    getPortalPageSetting(pageNameEnum.Bottom).then((data) => {
      this.desc = data.data.description;
    });

    getIsHideConactUs().then((data) => {
      this.isShowContactUs = !data.data;
    });
  },
  methods: {
    contactUs() {
      this.$router.push({
        name: "contactUs",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .contactUs {
    a {
      text-align: left;
      padding: 5px 15px;
      border: 1px solid #dddddd;
      border-radius: 24px;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      &:hover,
      &:focus {
        background-color: #eaeaea;
      }
    }
  }
}
</style>
