<template>
  <div style="margin-right: 0.6rem">
    <span
      id="usename_1"
      v-b-tooltip="{
        trigger: 'hover',
        title: userName,
        boundary: 'window',
        container: 'usename_1',
      }"
      @click="toMyProfile"
    >
      <b-icon icon="person-fill" font-scale="1.5" />
    </span>
  </div>
</template>

<script>
import { storeage } from "fe-infrastractures";
import { PW_Worker_Portal_Code } from "@/router/permissionCode";
export default {
  data() {
    return {
      PW_Worker_Portal_Code,
    };
  },
  computed: {
    userName() {
      if (
        storeage.getUserInfo()?.lastName &&
        storeage.getUserInfo()?.firstName
      ) {
        return (
          storeage.getUserInfo()?.lastName +
          ", " +
          storeage.getUserInfo()?.firstName
        );
      } else if (storeage.getUserInfo()?.lastName) {
        return storeage.getUserInfo()?.lastName;
      } else if (storeage.getUserInfo()?.firstName) {
        return storeage.getUserInfo()?.firstName;
      } else {
        return "";
      }
    },
  },
  methods: {
    toMyProfile() {
      if (
        !this.$has([
          this.PW_Worker_Portal_Code.MyProfile.BasicInformation,
          this.PW_Worker_Portal_Code.MyProfile.ChangePassword,
        ])
      ) {
        this.$alert({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.noPermission"),
          okText: this.$g("const.confirm.ok"),
        });
        return;
      }
      this.$router.push({ name: "my-profile" });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tooltip-inner {
  white-space: normal;
}
#usename_1 {
  display: inline-block;
  border-radius: 50%;
  padding: 2px;
  background-color: var(--topMenuFont);
  border: 2px solid var(--topMenuFont);
  color: var(--topMenuBackground);
  cursor: pointer;
  .b-icon.bi {
    display: block;
  }
  &:hover {
    background-color: var(--linkFontActive);
    border: 2px solid var(--linkFontActive);
    color: var(--topMenuFont);
  }
}
</style>
