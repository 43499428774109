<template>
  <div>
    <div class="languageTitle wapShow" ref="languageSelect">
      <div class="languageHeader" @click="showLanguage">
        <b-icon icon="gear-fill" :class="{ languageActive: isShow }" />
      </div>
      <div class="languageGroup" v-show="isShow">
        <a
          v-for="(item, index) in items"
          :key="index"
          @click="systemLanguageChange(item.value)"
          class="languageItem"
        >
          {{ item.text }}
          <b-icon
            icon="check"
            v-show="item.value == curSystemLanguage"
            style="color: #333 !important"
          />
        </a>
      </div>
    </div>
    <k-form-select
      v-model="curSystemLanguage"
      :options="systemLanguageList"
      @change="systemLanguageChange"
      class="wapHide"
      :aria-label="$g('header.language')"
    />
  </div>
</template>

<script>
import { storeage } from "fe-infrastractures";
import { getSysLanguageList } from "@/api/app";

export default {
  name: "SelectLanguage",
  props: {
    isHasSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      curSystemLanguage: storeage.getLanguageId(),
      systemLanguageList: [],
      items: null,
      isShow: false,
    };
  },
  methods: {
    systemLanguageChange(languageId) {
      this.isShow = !this.isShow;
      storeage.setLanguageId(languageId);
      this.$router.go(0);
    },
    bindLanguageList() {
      getSysLanguageList().then((resp) => {
        if (resp.data && resp.data.length > 0) {
          this.systemLanguageList = resp.data;
          this.items = resp.data;
        }
      });
    },
    showLanguage() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        document.addEventListener("click", this.languageSet);
      } else {
        document.removeEventListener("click", this.languageSet);
      }
    },
    languageSet(e) {
      if (!this.$refs.languageSelect.contains(e.target)) {
        this.isShow = false;
        document.removeEventListener("click", this.languageSet);
      }
    },
  },
  mounted() {
    this.bindLanguageList();
  },
};
</script>

<style lang="scss" scoped>
.languageTitle {
  position: relative;

  .languageHeader {
    padding: 0.25rem;
    cursor: pointer;
    display: inline-block;
    .b-icon.bi {
      color: var(--topMenuFont);
      &.languageActive {
        color: var(--linkFontActive);
      }
    }
  }

  .languageGroup {
    position: absolute;
    padding: 0.5rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    right: 10px;
    white-space: nowrap;
    .languageItem {
      display: block;
      padding: 0.5rem;
      cursor: pointer;
      color: #333333;
    }
  }
}
</style>
