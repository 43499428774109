import http from '../../node_modules/fe-infrastractures/src/utils/http'

export const messagePage = (data) => {
  return http.post('api/message-portal/page', data, { useBody: true })
}
export const messageSet = (data) => {
  return http.post('api/message-portal/read-status/set', data)
}
export const messageCount = (data) => {
  return http.get('api/message-portal/count', data)
}
export const messageDetail = (id) => {
  return http.get(`api/message-portal/${id}`)
}